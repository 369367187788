/*
 * Copyright (C) FLATFEECIO - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Matthew Woods mattwoods9170@gmail.com, April 2023
 *
 * This file is part of the FLATFEECIO Calculator project, owned by Don Rudolph.
 *
 * Unauthorized use of this file may result in legal consequences.
 * Proprietary and confidential information. Unauthorized use, reproduction,
 * or distribution of this file or any portion of it may be subject to civil
 * and criminal penalties, and the party responsible could be prosecuted.
 *
 * This file is provided without warranty. Use at your own risk.
 */

"use client";

import Typography from "@mui/material/Typography";
import { useRouter } from "next/navigation";
import React from "react";

export default function NotFound() {
  const router = useRouter();
  React.useEffect(() => {
    router.prefetch("/");
    setTimeout(() => {
      router.push("/");
    }, 3000);
  });

  return (
    <div style={{ textAlign: "center" }}>
      <Typography
        variant="h6"
        paragraph={true}
        style={{ marginBottom: "1rem", marginTop: "1rem" }}
      >
        Page not found, hold on while we redirect you...
      </Typography>
    </div>
  );
}
